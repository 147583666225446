<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="top">
        <Button type="primary" @click="add">{{$t('dataset_node_add')}}</Button>
        <div class="top-right">
          <Input v-model="keyword"
                 :placeholder="$t('common_search_keyword')"
                 clearable
                 search
                 :enter-button="true"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
          />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="typeId == index ? 'active' : ''" v-for="(item,index) in typeObj" :key="index + 'type'" @click="changeSearch({id:index},'typeId')">{{item}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="secondCategoryList.length > 1">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="thirdCategoryList.length > 1">
          <div class="name">
            三级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'thirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_node_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <!-- <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('dataset_node_table_name')" prop="name">
            <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('dataset_node_add_name_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_node_table_data_count')" prop="data_count">
            <Input :disabled="type == 'check'" v-model="formItem.data_count" :placeholder="$t('dataset_node_add_data_count_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_node_table_desc')" prop="desc">
            <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('dataset_node_add_desc_tip')"></Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="cancel">{{ $t('dataset_cancel') }}</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >{{ $t('dataset_confirm') }}</Button>
        </div>
      </Modal> -->
    </div>

    <el-drawer :visible.sync="modalStatus" :size="800" direction="rtl">
      <div slot="title" class="drawer-title">
        <div class="drawer-title-text">{{ modalTit }}</div>
        <div class="drawer-title-btns">
          <Button @click="cancel" style="margin-right: 10px;">{{ $t('dataset_cancel') }}</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >{{ $t('dataset_confirm') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('dataset_node_table_name')" prop="name">
            <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('dataset_node_add_name_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_node_table_data_count')" prop="data_count">
            <Input :disabled="type == 'check'" v-model="formItem.data_count" :placeholder="$t('dataset_node_add_data_count_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_node_table_desc')" prop="desc">
            <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('dataset_node_add_desc_tip')"></Input>
          </FormItem>
        </Form>
      </div>
    </el-drawer>

  </div>

</template>

<script>
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "role",
  data(){
    return{
      columns: [
        {
          title: this.$t('dataset_node_table_index'),
          key:'id'
        },
        {
          title: this.$t('dataset_node_table_name'),
          key: 'name',
        },
        {
          title: this.$t('dataset_node_table_data_count'),
          key: 'data_count',
        },
        {
          title: this.$t('dataset_node_table_desc'),
          key: 'desc',
        },
        {
          title: this.$t('dataset_node_table_op'),
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.check(params.row)
                  }
                }
              }, this.$t('system_check')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele'))
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        id:'',
        name:'',
        data_count:'',
        desc:'',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_node_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_node_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_node_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_node_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
      keyword:'',
      // 类型信息
      typeObj:{},
      // 类型id
      typeId:"0",
      // 抽屉可见性
      drawerShow:false
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getCategoryList();
    this.getList();
  },
  methods:{
    add(){
      this.modalTit = this.$t('dataset_node_add');
      this.type = '';
      this.formItem = {
        id:'',
        name:'',
        data_count:'',
        desc:'',
      };
      this.modalStatus = true;
      this.drawerShow=true;
    },
    check(data){
      this.modalTit = this.$t('dataset_node_check');
      this.type = 'check';
      this.formItem = {
        id:data.id,
        name:data.name,
        data_count:data.data_count,
        desc:data.desc
      };
      this.modalStatus = true;
    },
    edit(data){
      this.modalTit = this.$t('dataset_node_edit');
      this.type = 'edit';
      this.formItem = {
        id:data.id,
        name:data.name,
        data_count:data.data_count,
        desc:data.desc
      };
      this.modalStatus = true;
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        site_id:-1,
        user_id:-1,
        type:this.typeId
      };
      console.warn("入参",params);
      if(this.curThirdCategoryId && this.curThirdCategoryId != -1){
        params.category_id = this.curThirdCategoryId;
      }else if(this.cursecondCategoryId && this.cursecondCategoryId != -1){
        params.category_id = this.cursecondCategoryId;
      }else if(this.curCategoryId && this.curCategoryId != -1){
        params.category_id = this.curCategoryId;
      }
      this.api.dataset.nodeList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.dataset.nodeDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            id:this.formItem.id,
            data_count:this.formItem.data_count,
            name:this.formItem.name,
            desc:this.formItem.desc
          };
          this.addBtnLoading = true;
          if(this.formItem.id){
            this.api.dataset.nodeUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.dataset.nodeCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }
        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        is_hide_children:1,
        site_id:-1,
        user_id:-1,
        type:this.typeId
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        this.typeObj=res.types;

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];
        console.log(this.secondCategoryList,'this.secondCategoryList')
        this.thirdCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryThirdList
        ]
      })
    },
    changeSearch(data,name){
      if(name == 'curCategoryId'){
        this.cursecondCategoryId = '-1';
        this.curThirdCategoryId = '-1';
      }else if(name == 'cursecondCategoryId'){
        this.curThirdCategoryId = '-1';
      }else if(name == 'curStarTypeId'){
        this.starTypeSmall = '';
        this.starTypeBig = '';
        this.searchStarTypeSmall = '';
        this.searchStarTypeBig = ''
      }else if(name == 'curRightRateId'){
        this.rightRateSmall = '';
        this.rightRateBig = '';
        this.searchRightRateSmall = '';
        this.searchRightRateBig = '';
      }else if(name == 'typeId'){
        this.curCategoryId = '-1';
        this.cursecondCategoryId = '-1';
      }
      this[name] = data.id;
      this.page = 1;
      this.getCategoryList();
      this.getList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .top{
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 0;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: cneter;

    .drawer-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .drawer-title-btns {
      padding-right: 40px;
    }
  }
}
</style>
